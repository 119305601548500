


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { ceil, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../../components/Common/TableContainerLocation";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Location from "../Account/AdminLocation";



import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function ManageLocations() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cases, setCases] = useState([]);
  const [cancelNotificationType, setCancelNotificationType] = useState('')
  const [enableCancel, setEnableCancel] = useState(false);

  const [editLoc, setEditLoc] = useState({});

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);

  const [data, setData] = useState([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || "",
      billingName: (order && order.billingName) || "",
      orderdate: (order && order.orderdate) || "",
      total: (order && order.total) || "",
      paymentStatus: (order && order.paymentStatus) || "Paid",
      badgeclass: (order && order.badgeclass) || "success",
      paymentMethod: (order && order.paymentMethod) || "Mastercard",
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Order Id")
        .required("Please Enter Your Order Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Amount")
        .required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        // dispatch(onUpdateOrder(updateOrder));
        // validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        // dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [selectedNotification, setSelectedNotification] = useState({});

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

  getAllLocation();

  }, []);



  const get_all_cases = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/cases`, {}, config)

    .then(response => {

      console.log('response cases==>',response)

      const data = response.data;

      let arr = []

     data.map((item)=> {

      if(item.verification_status !== 'Complete'){
      
      arr.push({
        ...item,
        select:false
      })

    }

      })

    

      setCases(arr)


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const [isLoading, setLoading] = useState('')



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = (arg) => {
    const order = arg;
    setOrder({
      id: order.id,
      orderId: order.orderId,
      billingName: order.billingName,
      orderdate: order.orderdate,
      total: order.total,
      paymentStatus: order.paymentStatus,
      paymentMethod: order.paymentMethod,
      badgeclass: order.badgeclass,
    });

    setIsEdit(true);

    toggle();
  };

  const selectAll = (value, data) => {

    console.log('select all', value)

    let arr = [...data]

    arr.map((item)=> {

      item.select = value

    } )

    setCases(arr)

    setEnableCancel(value)



  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    // setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      // dispatch(onDeleteOrder(order.id));
      // setDeleteModal(false);
      setOrder("");
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: (cellProps) => <div className="form-check font-size-16" >
          <input className="form-check-input"  name="all" onClick={(e)=>{

            selectAll(e.target.checked, cellProps.data)
          
          }} type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: 'select',
        width: '20px',
        filterable: false,
        disableFilters: false,
        
      
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <Input className="form-check-input" type="checkbox" onClick={()=> {
              let arr = [...cellProps.data]


              // console.log('index', cellProps)
              arr[cellProps.row.index].select = !arr[cellProps.row.index].select

              setCases(arr)

              let selected = arr.filter((item)=> item.select === true)

              if(selected.length > 1) {
                setEnableCancel(true)
              }else{
                setEnableCancel(false)
              }

            
            }} checked={cellProps.value}   />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: "ID",
        accessor: "LocationID",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <Link to={`#`} disabled={true} onClick={(e)=>
          {
            e.preventDefault();

            const  status = cellProps.row.original.verification_status;

            if(status == 'Approved' || status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') {
              
              navigate('/edit-progress', {state: cellProps.row.original}) 

            }

          }
          } >{cellProps.value}</Link>;
        },
      },
      {
        Header: "Location",
        accessor: "location_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Country",
        accessor: "country",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "State",
        accessor: "state",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
    
      {
        Header: "City",
        accessor: "city",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

  
 
    ],
    []
  );

  const [modalConfirm, setModalConfirm] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);


  const cancelNotification = async(notification_id, walletID, email) => {

    console.log('selectedNotification==>',selectedNotification)


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const id = notification_id;

    const body = {
      id_identity_user: walletID? walletID : '',
      email: email
    }

    console.log('id==>',id, body)

    axios.put(`${process.env.REACT_APP_BASE_URL}/service/cancelNotification/${id}`, body, config)

    .then(response => {

      console.log('cancel notification==>',response)

      setModalConfirm(false)

      get_all_cases();


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const handleCancelClicks = () => {

    console.log('cancel clicked', cases)

   setCancelNotificationType('multiple')

   setModalConfirm(true)

   
  }

  const cancelAllNotification = async() => {

    console.log('cases cancel all==>',cases)

      cases.map((item)=> {

        if(item.select) {
          cancelNotification(item.notification_id, item.walletID, item.email)
        }

      }
      )

  }

  const reFetch = async () => {

    setModal(false);

    getAllLocation();

  }

  const getAllLocation = async () => {

  
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


  
   await axios.get(`${process.env.REACT_APP_BASE_URL}/service/admin/locations`, {}, config)
    .then(response => {
      console.log('all location response',response);

        let data = response.data;

        setData(data);
  
    })
    .catch(error => {
  
      console.log('error==>',error);
    });

  };

  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}

          <Modal isOpen={modal} centered={true} size="xl" toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "Edit Location" : "Add Location"}
            </ModalHeader>  
            <ModalBody>
          <Location data={editLoc} isEdit={isEdit} reFetch={reFetch} />
            </ModalBody>
          </Modal>


          <Row>
            {
            
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <p className="card-title mb-4" style={{fontSize:20, fontWeight:'500'}}>Manage Locations</p>



                      <TableContainer
                        columns={columns}
                        data={data.length > 0 ? data : []}
                        isFilter={true}
                        isGlobalFilter={false}
                        isAddOptions={true}
                        enableCancel={enableCancel}
                        handleOrderClicks={handleOrderClicks}
                        handleCancelClicks={handleCancelClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>


        </div>
      </div>

    </React.Fragment>
  );
}
ManageLocations.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default ManageLocations;
