import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Popover,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,

  NavLink,
  CardTitle,
  CardText,
  
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classnames from "classnames";


//import action
// import { getChartsData as onGetChartsData } from "../../../MainStore/slices/dashboard/actions";

import modalimage1 from "../../../assets/images/product/img-7.png";
import modalimage2 from "../../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import ActivityComp from "./ActivityComp";
import LatestTranaction from "./LatestTranaction";
import LatestIssued from "./LatestIssued";
import axios from 'axios'
import Revenue from "./Revenue";


//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import CardUser from "./CardUser";
import moment from "moment";
// import { addProfilePicture } from "MainStore/slices/e-commerce/actions";


const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const [activityLogs, setActivityLogs] = useState([])

  const [popoverdismiss, setpopoverdismiss] = useState(false);



  const [data, setData] = useState([])

  const [univ_details, setUnivDetails] = useState({})

  const [authUser, setAuthUser] = useState({})

  const [certs, setCerts] = useState([])

  const [revenue, setRevenue] = useState(0)
  const [bgv_revenue, setBgvRevenue] = useState(0)
  const [total_bgv_verified, setTotalBgvVerified] = useState(0)
  const [cert_revenue, setCertRevenue] = useState(0)

  const [certFetched, setCertFetched] = useState(0)
  const [popovertop, setpopovertop] = useState(false);

  const [attestaion, setAttestation] = useState({
    total_attestation:0,
    total_sent:0,
    total_approved:0,
    total_pending:0,
    total_stopped:0,
    total_rejected:0

  })


  const [reports, setReports] = useState(
    [
      { title: "Total verification performed", iconClass: "bx-copy-alt", description: "1,235", state:'verification_performed' },
      { title: "Pending verifications", iconClass: "bx-archive-in", description: "$35, 723", state:'pending_verification' },
      {
        title: "Revenue",
        iconClass: "bx-purchase-tag-alt",
        state:'revenue',
        description: "$16.2",
      },
    ]
  )

  const [state, setState] = useState({
    certIssued:0,
    certRevoked:0,
    certFetched:0,
    monthlyEarning:0,
    all:[],
    one_year:[],
    one_month:[],
    six_month:[],
    revenue:0
  });

  const [cred_issued, setCredIssued] = useState({
    today:0,
    month:0,
    year:0
  })


const [total_verification, setTotalVerification] = useState(0)

const [pending_verification, setPendingVerification] = useState(0)

  const [cases, setCases] = useState([])


  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  //meta title
  document.title = "TheRollNumber";


  useEffect(() => {

    get_all_cases()

    get_all_attestation()

  }, []);

  const get_all_cases = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/cases`, {}, config)

    .then(response => {

      console.log('response cases==>',response)

      const data = response.data;

      setCases(data)

      let total_verification_arr =  data.filter((item) => item.verification_status === 'Complete')

      let pending_verification_arr =  data.filter((item) => item.verification_status !== 'Complete')

      setTotalVerification(total_verification_arr.length)

      setPendingVerification(pending_verification_arr.length)


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const get_all_attestation = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/all_attestation`, {}, config)

    .then(response => {

      console.log('response attestation==>',response.data)

      const data = response.data.data;

      // setCases(data)

      let total_attestation =  data.length

      let total_sent =  data.filter((item) => item.status == 'sent')

      let total_approved =  data.filter((item) => item.status == 'approved')

      let total_pending =  data.filter((item) => item.status == 'pending')

      let total_stopped =  data.filter((item) => item.status == 'stop')

      let total_rejected =  data.filter((item) => item.status == 'rejected')

      setAttestation({
        total_attestation:total_attestation,
        total_sent:total_sent.length,
        total_approved:total_approved.length,
        total_pending:total_pending.length,
        total_stopped:total_stopped.length,
        total_rejected:total_rejected.length
      })

      // setTotalVerification(total_verification_arr.length)

      // setPendingVerification(pending_verification_arr.length)


    })

    .catch(error => {

      console.log('attestation error from server==>',error)

    })


  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
    

          <Row>
            <Col xl="4">
              {/* <button onClick={test}>click</button> */}
              <WelcomeComp user={authUser} univ={univ_details} />
              {/* <MonthlyEarning earning={bgv_revenue} bgv_verified={total_bgv_verified} /> */}
              {/* <SocialSource /> */}

              <Revenue attestation={attestaion}  />


            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid" style={{height:130}}>
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">

<div className="d-flex" style={{alignItems:"center"}}>
                            <p className="text-muted fw-medium mb-1">
                              {report.title}
                            </p> 
{              report.state == 'revenue' &&              <i id="Popovertop" onClick={()=>setpopovertop(!popovertop)}  style={{marginLeft:5}} className="bx bx-info-circle mr-2"></i>
}
                            </div>
                            {
                              report.state === 'verification_performed' &&
                              <h4 className="mb-0">{total_verification}/{cases.length}</h4>
                            }
                                {
                              report.state === 'pending_verification' &&
                              <h4 className="mb-0">{pending_verification}</h4>
                            }
                            {
                              report.state == 'revenue' && 
                              <div className="d-flex" style={{ alignItems:"center"}}>
      
      
                    <UncontrolledPopover
                      placement="top"
                      isOpen={popovertop}
                      // style={{background:"lightgrey"}}
                      trigger="focus"
                      target="Popovertop"
                      toggle={() => {
                        setpopovertop(!popovertop);
                      }}
                    >
                      <PopoverBody  >
                        <div className="d-flex text-center" >
                          <p className="mb-2">Certificate Revenue</p>
                          <h5 className="mb-0" style={{marginLeft:5}}>₹{cert_revenue}</h5>

                        </div>

                        <div className="d-flex text-center">
                          <p className="mb-2">Bgv Revenue</p>
                          <h5 className="mb-0" style={{marginLeft:5}}>₹{bgv_revenue}</h5>

                        </div>
                         
                      </PopoverBody>
                    </UncontrolledPopover>
                              <h4 className="mb-0">{revenue}</h4>

                              </div>
                            }
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

    
                  {/* <div className="clearfix"></div> */}
                  <CardUser dataColors='["--bs-primary", "--bs-warning"]' data={state} creds = {cred_issued} />
                  {/* <ActivityComp logs={activityLogs} /> */}


                  {/* <StackedColumnChart periodData={periodData} dataColors='["--bs-primary", "--bs-warning", "--bs-success"]' /> */}
     
            </Col>
          </Row>


          <Row>
            <Col lg="12">


           

            </Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
  
};

export default withTranslation()(Dashboard);
